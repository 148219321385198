export default {
  type: "payment-terms",
  name: null,
  excerpt: "",
  relationshipNames: ["organization"],
  organization: {
    type: "organizations",
    id: null,
  },
};
