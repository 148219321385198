<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="paymentTerm.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            paymentTerm.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.NAME')} (*)`"
      :placeholder="$t('COMMON.NAME')"
      v-model="paymentTerm.name"
      @change="onFormChanged()"
    />
    <validation-error :errors="apiValidationErrors.name" />

    <base-input
      :label="$t('COMMON.EXCERPT')"
      :placeholder="$t('COMMON.EXCERPT')"
    >
      <html-editor v-model="paymentTerm.excerpt" @change="onFormChanged()">
      </html-editor>
    </base-input>
    <validation-error :errors="apiValidationErrors.excerpt" />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          paymentTerm.id
            ? $t("PAYMENT_TERMS.EDIT_PAYMENT_TERM")
            : $t("PAYMENT_TERMS.ADD_PAYMENT_TERM")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  layout: "DashboardLayout",

  name: "payment-term-form",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["paymentTermData", "formErrors", "loading"],

  data() {
    let paymentTermData = cloneDeep(this.paymentTermData);
    paymentTermData = this.$fillUserOrganizationData(paymentTermData);
    return {
      paymentTerm: paymentTermData,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let paymentTermData = cloneDeep(this.paymentTerm);
      paymentTermData = this.$fillUserOrganizationData(paymentTermData);
      this.$emit("paymentTermSubmitted", paymentTermData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    paymentTermData(paymentTermData) {
      if (paymentTermData) {
        this.paymentTerm = {
          ...this.paymentTerm,
          ...cloneDeep(paymentTermData),
        };
      }
    },
  },
};
</script>
